@import url("./themes.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-primary)!important;
}

#root{
  width: 100vw!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.comics-page .header {
  margin-left: 0 !important;
  margin-top: 30px !important;
  margin-bottom: 50px !important;
  font: 800 26px/1 "RobotoCondensed", "Trebuchet MS", Helvetica, Arial,
    sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.series-page .header {
  margin-left: 0 !important;
  margin-top: 30px !important;
  margin-bottom: 50px !important;
  font: 800 26px/1 "RobotoCondensed", "Trebuchet MS", Helvetica, Arial,
    sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
}

#slide-comics-image {
  padding: 50px 0;
  width: 100%;
  background-image: url(../img/slide-comics.jpeg);
  height: 350px;
  background-position: top;
}

#slide-series-image {
  padding: 0 0 50px 0;
  width: 100%;
  background-image: url(../img/last-movies.jpeg);
  height: 350px;
  background-position: top;
}

.error-404 {
  width: 100%;
  background-image: url(../img/page-404.jpeg);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
}

.error-404-content-text {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100vh;
}

.error-404 .error-404-content-text h1 {
  color: white;
}
.error-404 .error-404-content-text h2 {
  color: white;
  margin-bottom: 50px;
}
.error-404 .error-404-content-text .error-404-content-text-container {
  text-align: center;
}

.responsive-img {
  display: flex;
  width: 100%;
  height: auto;
}

#empty-block {
  width: 100%;
  height: 1rem;
  background-color: var(--background-primary);
  margin-top: -1rem;
}

#button_page_error404{
  background-color: var(--button-bg-primary);
  color: var(--text-callaction-button);
}