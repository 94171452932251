@import url("../../css/themes.css");

.grid-container-error{
    background-color: var(--background-primary);
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0!important;

    #error-text,
    #image-error{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
    }

}