.best-characters {
  padding: 70px 0;

  .vertically {
    display: flex;
    align-items: center;
  }

  h1.header {
    font-size: 50px;
    color: var(--text-primary);
  }

  h3.header {
    margin-top: 25px;
    font-size: 28px;
    color: var(--text-secondary);
  }

  button.button {
    background-color: var(--button-bg-primary);
    margin: 2.5rem 0 0;
    font-size: 20px;
    padding: 20px;
    line-height: 1.5rem;
    color: var(--text-primary);
    text-transform: uppercase;

    &:hover {
      background-color: var(--button-bg-primary);
      color: var(--background-primary);
      transition: background-color 0.15s ease-in-out;
    }

    &:focus {
      background-color: var(--button-bg-primary);
    }
  }

  .column.image-container img {
    width: 100%;
    height: auto;
  }
}
