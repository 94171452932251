@import url("../../css/themes.css");

.container-list-last-events {
    padding: 70px 0 20px 0;
    background: var(--background-primary);
  
    .container-bg {
      padding-top: 20px;
    }
  
    .header {
      text-align: center;
      color: var(--text-primary);
      margin-bottom: 20px;
      font-weight: 200;
      font-size: 3.125rem;
      text-transform: uppercase;
    }
  }