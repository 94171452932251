@import url("../../css/themes.css");

.cards {
  background-color: var(--background-secondary);
}

#title-comics {
  margin-top: 0 !important;
  padding-top: 30px !important;
}

#slide-comics-image {
  padding-top: 0 !important;
}

.list-comics {
  background-color: var(--background-primary)!important;

  .content,
  .content.extra {
    background-color: var(--background-primary)!important;
  }

  .visible.content{
    background-color: var(--background-secondary)!important;
  }

  .content .header{
    color: var(--text-primary)!important;
  }

  .hidden.content{
    color: var(--text-primary)!important;
    background-color: var(--background-secondary)!important;
  }

  div.content a {
    background-color: var(--background-secondary)!important;
    color: var(--text-primary)!important;
  }

  .header {
    height: 60px;
    color: var(--text-primary);
  }

  .image {
    height: 300px;
    display: flex !important;
  }
}

.container-button {
  text-align: center;
  width: 100%;
  padding: 30px 0;
}
