@import url("../../css/themes.css");

.header-menu {
  background-color: var(--header-bg);
  padding: 10px 30px;
  
  #items-container{
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .menu .item {
    color: #ffffff !important;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.03125rem;
    text-transform: uppercase !important;
  }

  .menu .active.item {
    background: none !important;
    color: #ffffff !important;
  }
  .active::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background: rgba(0, 163, 218, 0);
    -webkit-transition: background-color 0.08s ease-out;
    transition: background-color 0.08s ease-out;
    background-color: #00a3da;
  }

  #button-theme {
    background-color: var(--background-primary);
    color: var(--text-primary)!important;
  }
}
